<template>
  <div class="avatar-sidebar">
    <div class="avatar" @click="isOpen = true">
      <div class="header">
        <el-avatar class="header__image" shape="square" :src="squareUrl"> </el-avatar>
        <i class="el-icon-arrow-down el-icon--right"></i>
      </div>
    </div>
    <b-sidebar
      v-model="isOpen"
      v-if="myInfo"
      class="sidebar-content"
      id="sidebar-right"
      title="Sidebar"
      right
      shadow
      backdrop
    >
      <div class="sidebar-content__icon-back">
        <i @click="isOpen = false" type="button" class="ion ion-close-outline"> </i>
      </div>
      <div class="profile-information">
        <div class="profile-information__item">
          <div class="header__avatar">
            <el-avatar class="header__image" shape="square" :src="squareUrl"></el-avatar>
            <div class="header__information">
              <p>{{ username }}</p>
              <span>{{ firstName }}</span>
            </div>
          </div>
        </div>
        <div class="profile-information__item">
          <div class="avatar-content__logout mt-4">
            <div class="avatar-content__logout-content" @click="logout()">
              <b>Logout</b>
              <i class="ion ion-log-out-outline"></i>
            </div>
          </div>
        </div>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import onReSize from "@/Extend/onResize";
import DisableScroll from "../Extend/DisableScroll";
import SetLogoutPath from "../utils/SetLogoutPath";

export default {
  name: "AvatarSidebar",
  mixins: [DisableScroll, onReSize],
  data() {
    return {
      squareUrl: "https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png",
      myInfo: null,
      username: null,
      isOpen: false,
      firstName: null,
    };
  },
  async created() {
    this.username = this.$store.getters["login/getTokenInfo"].username;
    this.myInfo = this.$store.getters["user/getMyInfo"];
    // if (!this.myInfo) {
    //   this.myInfo = await this.$store.dispatch("user/getAllMyInfo");
    // }
    this.firstName = this.$store.getters["user/getMyInfo"].first_name;
  },
  watch: {
    screenWidth() {
      this.isOpen = false;
      window.removeEventListener("scroll", this.disableScroll);
    },
  },
  methods: {
    logout() {
      window.removeEventListener("scroll", this.disableScroll);
      SetLogoutPath();
      localStorage.clear();
    },
  },
};
</script>

<style scoped lang="scss">
.avatar {
  font-family: $font-family-portal;
  width: 100%;
  border-radius: 15px;
  background: #efefef;
  z-index: 210;
  cursor: pointer;
  color: $color-primary-company;
}
.header {
  padding: 7px 0px;
  justify-content: space-around;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.header__avatar {
  display: flex;
  justify-content: flex-start;
  @include font-small-information;
  .header__information {
    display: flex;
    flex-direction: column;
    margin-left: 15px;
    text-align: start;
    p {
      margin: 0;
    }
    span {
      font-weight: bold;
    }
  }
}
.sidebar-content {
  font-family: $font-family-portal;
  //  border: 1px solid $color-border-container;
  &__icon-back {
    text-align: end;
    padding: 20px;
    i {
      line-height: 30px;
      width: 30px;
      height: 30px;
      text-align: center;
      font-weight: bold;
      color: $color-primary-company;
      font-size: 20px;
    }
  }
}
.profile-information {
  height: 90%;
  padding: 20px;
  color: $color-primary-company;
  &__item {
    text-align: start;
  }
}
.avatar-content {
  margin: 30px auto;
  text-align: start;
  font-weight: bold;
  &__title {
    margin: 40px auto;
    margin-bottom: 15px;
  }
  b {
    @include font-small-information;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .settings-icon {
    margin-left: 45px;
  }
  &__items {
    position: relative;
    margin: 10px auto;
    @include font-small-information;
    font-weight: bold;
    p {
      margin: 0;
    }
    span {
      font-weight: 400;
    }
  }
  &__logout {
    display: inline-block;
    text-align: start;
    cursor: pointer;
    &-content {
      display: flex;
      position: sticky;
      bottom: 5px;
      align-items: center;
    }
    b {
      margin: 0px;
    }
    i {
      font-size: 20px;
      margin-left: 5px;
    }
  }
}
.language {
  margin-top: 5px;
}
::v-deep .b-sidebar {
  border: 1px solid $color-border-container;
  background-color: $color-gray-light;
}
::v-deep .b-sidebar-header {
  display: none;
}

::v-deep .el-avatar--square {
    border-radius: 25px;
}
</style>
