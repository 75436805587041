<template>
  <div
    :class="activeCollapse ? 'sidebar-collapse' : 'sidebar'"
    id="menuSidebar"
    v-click-outside="toggleMenu"
  >
    <div class="sidebar-content" :class="[{ 'collapse-item': activeCollapse }]">
      <div>
        <div class="active-collapse">
          <img
            class="active-collapse__icon"
            :src="activeCollapse ? iconActiveCollapse : iconInactiveCollapse"
            alt=""
            @click="activeCollapse = !activeCollapse"
          />
        </div>
        <div class="sidebar-content__top" :class="+'__top'">
          <img :src="sidebarLogo" alt="GLT LOGO" class="sidebar-content__logo" :class="+'__logo'" />
          <i
            @click="toggleMenu(false)"
            class="ion ion-close-outline sidebar-content__icon-back"
            :class="+'__icon-back'"
          ></i>
        </div>
        <template v-for="(option, index) of sidebarOptions">
          <div
            v-if="option.role.includes('all') || option.role.includes(role.toLowerCase())"
            :key="index"
            @click="selectOption(option)"
            @mousedown.middle="middleClickHandler(option)"
            class="sidebar-content__option"
            :class="
              $route.path.includes(option.pathRoute) ? highlightOption : 'sidebar-content__option'
            "
            v-show="!($t(option.text) === 'Insurance' && !insuranceEnable) && !(option.pathName === 'paymentHub' && tokenInfo.is_branded)"
          >
            <div class="sidebar-content__icon-content">
              <span
                v-if="activeCollapse"
                v-b-popover.hover="$t(option.text)"
                class="warning-alert-icon"
              >
                <img class="sidebar-content__icon" :src="option.icon" alt="" />
              </span>
              <img v-else class="sidebar-content__icon" :src="option.icon" alt="" />
            </div>
            <span v-if="!activeCollapse" class="sidebar-content__text" :class="+'__text'">
              {{ $t(option.text) }}
            </span>
          </div>
        </template>
      </div>
      <div
        class="brand-quote"
        :class="+'__brand-quote'"
        v-if="showSidebarFooter && !activeCollapse"
      >
        <div v-if="!tokenInfo.is_branded" class="brand-quote__terms-container">
          <p class="brand-quote__t-and-c">
            <a href="https://goglt.com/shipping-service-guidelines/" target="_blank">Guidelines</a>
          </p>
          <p class="brand-quote__t-and-c">
            <a href="https://goglt.com/terms-and-conditions/" target="_blank">T & C</a>
          </p>
        </div>
        <p class="brand-quote__brand">GLT Logistics ® - 2022</p>
        <p class="brand-quote__brand">
          <i>{{ versionValue }}</i>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable operator-linebreak */
/* eslint-disable global-require */
// import Axios from "axios";
import { mapGetters } from "vuex";
import CollapseSidebar from "../Extend/CollapseSidebar";
import modes from "../views/QuoteBook/modes";

const modesList = Object.values(modes);

export default {
  name: "Sidebar",
  mixins: [CollapseSidebar],
  directives: {
    "click-outside": {
      bind: (el, binding, vnode) => {
        el.clickOutsideEvent = (event) => {
          if (
            !(el === event.target || el.contains(event.target)) &&
            event.target.id !== "hamburger-button"
          ) {
            vnode.context[binding.expression](event);
          }
        };
        document.body.addEventListener("click", el.clickOutsideEvent);
      },
      unbind: (el) => {
        document.body.removeEventListener("click", el.clickOutsideEvent);
      },
    },
  },
  data() {
    return {
      // sidebarOptions: [],
      role: null,
      tokenInfo: null,
      logoUrl: "",
      showSidebarFooter: true,
      activeCollapse: false,
      iconActiveCollapse: require("@/assets/scss/icons/righsolid.svg"),
      iconInactiveCollapse: require("@/assets/scss/icons/downSolid.svg"),
      insuranceEnable: null,
      versionValue: null,
      sidebarLogo: null,
    };
  },
  created() {
    this.versionValue = process.env.VUE_APP_PORTAL_VERSION;
    this.tokenInfo = this.$store.getters["login/getTokenInfo"];
    this.role = this.tokenInfo.role;
    if (this.tokenInfo.magaya_guid || this.tokenInfo.is_branded) {
      this.showSidebarFooter = false;
    }
    this.insuranceEnable = JSON.parse(
      localStorage.getItem("user-settings")
    ).can_dispatch_modes.insurance;
    this.sidebarLogo = this.setLogo();
  },
  computed: {
    ...mapGetters({
      isOpen: "getIsOpenSidebar",
      sidebarOptions: "getSidebarOptions",
      reenderizeSidebarLogoAgain: "getRenderSidebarLogo",
    }),
    widthSidebar() {
      if (this.isOpen) {
        return true;
      }
      return false;
    },
    highlightOption() {
      if (this.isOpen) {
        return "sidebar-content__selected__open";
      }
      return "sidebar-content__selected__close";
    },
  },
  watch: {
    isOpen: {
      immediate: true,
      handler() {
        this.$nextTick(() => {
          if (!this.isOpen) {
            this.hideSidebar();
          } else {
            this.showSidebar();
          }
        });
      },
    },
    activeCollapse: {
      immediate: true,
      handler() {
        this.$store.commit("setCollapseSidebar", this.activeCollapse);
      },
    },
    reenderizeSidebarLogoAgain: {
      handler() {
        this.sidebarLogo = this.setLogo();
      },
    },
  },
  methods: {
    setLogo() {
      /* eslint-disable global-require */
      if (!this.tokenInfo.magaya_guid) {
        const logoUrl = require("../assets/images/GLT-LOGISTICS-(TRADEMARK-NEGATIVO).png");
        const localStorageData = JSON.parse(localStorage.getItem("user-settings"));
        const themeActual = localStorageData.chosen_theme;
        let sidebarLogo = "";
        for (let i = 0; i < localStorageData.themes.length; i += 1) {
          if (localStorageData.themes[i].name === themeActual) {
            sidebarLogo = localStorageData.themes[i].logo;
            break;
          }
        }
        return sidebarLogo || logoUrl;
      }
      return require("../assets/images/MAGAYA-LOGO.png");
    },
    toggleMenu() {
      if (window.innerWidth < 992) {
        this.$store.commit("changeDataSidebar", false);
      }
    },
    selectOption(view) {
      if (view.external) {
        this.openNewTab(view.href);
        return;
      }
      this.$store.commit("triggerAbortController");
      this.$store.commit("account/resetAccounts");
      this.resetChosenLoadVuexVariables();
      const { name } = this.$route;
      if (view.pathName === "QuoteBook" && name === view.pathName && this.$route.params.loadId) {
        this.$store.commit("load/setClearLoadInformation");
      }
      this.changeView(view.pathName, this.$route);
      this.toggleMenu();
    },
    changeView(pathName, route) {
      let params;
      if (pathName.toLowerCase() === "quotebook") {
        const defaultMode = modesList.find((mode) => mode.enabled);
        params = { quoteType: defaultMode.id };
      }
      if (this.$route.name !== pathName) {
        this.$router.push({ name: pathName, params }).catch((err) => {
          if (err.name !== "NavigationDuplicated") throw err;
        });
      }
      if (pathName.toLowerCase() === "myloads") {
        this.$store.commit("setMyLoadsSectionFlag");
      } else if (route.name.toLowerCase() === "quotebook" && "loadId" in route.params) {
        this.$router.push({ name: pathName });
      }
    },
    resetChosenLoadVuexVariables() {
      this.$store.commit("carriers/resetCarriersList");
      this.$store.commit("load/resetChosenload");
      this.$store.commit("load/stops/resetChosenStops");
      this.$store.commit("load/accessorials/resetChosenAccessorials");
      this.$store.commit("load/lineItems/resetSelectedLineItems");
    },
    middleClickHandler(option) {
      if (option.external) {
        this.openNewTab(option.href);
        return;
      }
      const routeData = this.$router.resolve({ name: option.pathName });
      let path = routeData.href;
      if (option.pathName.toLowerCase() === "quotebook") {
        path = `/quote-book/ltl`;
      }
      this.openNewTab(path);
    },
    openNewTab(path) {
      window.open(path, "_blank");
    },
    hideSidebar() {
      const sidebar = document.getElementById("menuSidebar");
      sidebar.style.left = "-500px";
      sidebar.scrollTo(0, 0);
    },
    showSidebar() {
      const sidebar = document.getElementById("menuSidebar");
      sidebar.style.left = "0";
    },
  },
};
</script>

<style scoped lang="scss">
.sidebar {
  font-family: $font-family-portal;
  @include font-small-standart-text;
  position: fixed;
  top: 0px;
  left: -500px;
  height: 100%;
  max-width: 160px;
  width: calc(100% - 143px);
  background: $color-sidebar 0% 0% no-repeat padding-box;
  color: $color-sidebar-text;
  opacity: 1;
  z-index: 500;
  box-shadow: 2px 2px 6px $color-shadow-sidebar;
  transition: ease 0.3s all;

  @media (max-width: 600px) {
    max-width: 65%;
  }
}

.sidebar-collapse {
  font-family: $font-family-portal;
  @include font-small-standart-text;
  position: fixed;
  top: 0px;
  left: -500px;
  height: 100%;
  max-width: 60px;
  background: $color-sidebar 0% 0% no-repeat padding-box;
  color: $color-sidebar-text;
  opacity: 1;
  z-index: 500;
  box-shadow: 2px 2px 6px $color-shadow-sidebar;
  transition: ease 0.3s all;

  @media (max-width: 600px) {
    max-width: 65%;
  }
}

.sidebar-content {
  &__top {
    position: relative;
    display: flex;
    margin: 5px 5px 15px 9px;
    justify-content: center;
  }

  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__option {
    padding: 10px 0px;
    padding-left: 5px;
    margin: 4px 4px 7px 4px;
    opacity: 1;
    display: flex;
    flex-direction: row;
    vertical-align: middle;
    cursor: pointer;
  }

  &__option:hover {
    background: $color-blue-hover 0% 0% no-repeat padding-box;
    text-decoration: none;
    cursor: pointer;
  }

  &__selected {
    .sidebar-content__text {
      margin-left: 13%;
    }

    &__open {
      background-color: $color-sidebar-selected-option;
      border-radius: 5px;
      border: 2px;

      &:hover {
        border-radius: 5px;
        background-color: var(--color-sidebar-selected-option);
        border: 2px;
      }
    }

    &__close {
      display: none;
    }
  }

  &__icon-content {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__icon {
    margin-left: 5px;
    color: $color-white;
    width: 22px;
    height: 22px;
  }

  &__icon-back {
    position: absolute;
    right: -7px;
    top: -10px;
    font-size: 20px;
    font-weight: bold;
    margin: 10px;

    @media (min-width: 992px) {
      display: none;
    }
  }

  &__logo {
    @media (max-width: 600px) {
      max-width: 200px;
    }

    max-height: auto;
    max-width: 150px;
    width: 78%;
  }

  &__text {
    text-align: left;
    text-decoration: none;
    font-weight: 500;
    margin-left: 16px;
  }
}

.brand-quote {
  margin-bottom: 10px;

  @media (max-width: 500px) {
    text-align: start;
    margin-left: 25px;
  }

  &__t-and-c {
    font-size: 0.75rem;
    margin: 0px;
    text-align: center;
    font-style: italic;

    a {
      color: white;
      text-decoration: underline;
    }
  }

  &__terms-container {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 5px;
  }

  &__name {
    margin: 0px;
  }

  &__brand {
    @include brand-quote($color-white);
    margin: 0px;
    font-weight: lighter;
    font-size: 0.75rem;
    text-align: center;
  }
}

.active-collapse {
  display: flex;
  justify-content: flex-end;
  margin-right: 10px;

  &__icon {
    width: 15px;
    margin-top: 6px;
    cursor: pointer;

    @media (max-width: 992px) {
      display: none;
    }
  }
}

.collapse-item .sidebar-content__option {
  justify-content: center;
  padding-left: 0px;
  margin: 4px 4px 7px 4px;
}

.collapse-item .sidebar-content__icon {
  margin-left: 0px;
}

.collapse-item .sidebar-content__logo {
  width: 100%;
  margin: 20px 0px 30px 0px;
}
</style>
