<template>
  <div class="app">
    <div class="sidebar-overlay" v-if="sidebarIsOpen"></div>
    <div class="app-sidebar">
      <Sidebar />
    </div>
    <div
      :class="activeCollapse ? 'app-content-collpase' : 'app-content'"
    >
      <Header />
      <router-view />
    </div>
    <button
      v-if="screenWidth <= 800"
      @click="goScrollTop"
      class="scrollTop"
      id="scrollTop"
    >
      <i class="ion ion-chevron-up-outline"></i>
    </button>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Sidebar from "../../components/Sidebar.vue";
import Header from "../../components/Header.vue";
import onReSize from "../../Extend/onResize";
import SetFavicon from "../../utils/SetFavicon";

export default {
  /* eslint-disable max-len */
  name: "Layout",
  mixins: [onReSize],
  components: {
    Sidebar,
    Header,
  },
  data() {
    return {
      sidebarIsOpen: false,
      screenWidth: null,
      firstAccount: null,
      activeCollapse: false,
    };
  },
  computed: {
    ...mapGetters({ isOpen: "getIsOpenSidebar", isCollapseSidebar: "getCollapseSidebar" }),
    ...mapGetters({
      socketResponse: "carriers/getCarriersSocket",
    }),
    ...mapGetters({ areGeneratedDocs: "load/docsGenerate/getGeneratedDocs" }),
  },
  watch: {
    isOpen(value) {
      this.sidebarIsOpen = value;
    },
    isCollapseSidebar(value) {
      this.activeCollapse = value;
    },
    socketResponse() {
      if (
        this.socketResponse.notification === "new-quote" ||
        this.socketResponse.notification === "easy-post"
      ) {
        const carriers = JSON.parse(this.socketResponse.data.carriers);
        const shp = this.socketResponse.data.shp
          ? `${this.socketResponse.data.shp} `
          : "";
        /* eslint-disable camelcase */
        const { load_id } = this.socketResponse.data;
        if (load_id) {
          if (carriers.length) {
            this.openNotification(load_id, shp);
          }
        }
      }
    },
    areGeneratedDocs: {
      deep: true,
      handler(value) {
        if (value.notify) {
          this.generatedDocuments(value.shp);
        }
      },
    },
  },
  async created() {
    const token = await this.$store.dispatch(
      "login/decodeToken",
      this.$ls.get("portal-front-token")
    );
    const tokenInfo = JSON.parse(token.sub);
    SetFavicon(tokenInfo.magaya_guid);
  },
  mounted() {
    window.addEventListener("resize", this.onReSize);
    if (this.screenWidth && this.screenWidth < 800) {
      window.addEventListener("scroll", this.handleScroll);
    }
  },
  methods: {
    onReSize() {
      this.screenWidth = window.innerWidth;
      if (this.screenWidth > 993) {
        this.sidebarIsOpen = false;
      }
    },
    touchEventListener(e) {
      e.preventDefault();
      e.stopPropagation();
      return false;
    },
    openNotification(id, shp) {
      const { name } = this.$route;
      this.$notify.success({
        title: "Carrier Info",
        onClick: () => {
          if (name === "CarriersInfo" && this.$route.params.loadId === id) {
            return;
          }
          window.location.href = `${window.location.origin}/quote-book/carrier-selection/${id}`;
        },
        message: `The load ${shp}has new carriers!`,
      });
    },
    generatedDocuments(shp) {
      this.$notify.success({
        title: "Documents Generated",
        message: `${shp}`,
      });
    },
    handleScroll() {
      const btnScroll = document.getElementById("scrollTop");
      window.onscroll = () => {
        if (window.scrollY < 300) {
          btnScroll.classList.remove("scrollTop-on");
        } else {
          btnScroll.classList.add("scrollTop-on");
        }
      };
    },
    goScrollTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.app-content {
  font-family: $font-family-portal;
  margin-left: 170px;
  max-height: 98vh;
}
.app-content-collpase {
  font-family: $font-family-portal;
  margin-left: 60px;
  max-height: 98vh;
}
.scrollTop {
  background-color: $color-border-container;
  color: $color-white;
  border: none;
  position: fixed;
  z-index: 999;
  bottom: 1vw;
  right: 3vw;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  margin-bottom: 0.6rem;
  text-align: center;
  transform: scale(0);
  transition: 0.3s;
}

.scrollTop-on {
  transform: scale(1);
}
@media (max-width: 991px) {
  .sidebar-overlay {
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: $color-black;
    opacity: 0.5;
    z-index: 220;
  }
}

@media (max-width: 992px) {
  .app-content {
    margin-left: 0px;
  }
}
</style>
