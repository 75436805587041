<template>
  <div class="header">
    <div class="header-container">
      <div class="menu-icon-content" v-if="screenWidth && screenWidth < 992">
        <i class="ion ion-menu-outline" id="hamburger-button" @click="toggleMenu(true)"></i>
      </div>
      <div class="searchbar-container">
        <div class="title-content" v-if="changeTitleName() && screenWidth > 800">
          <span> {{ changeTitleName() }}</span>
          <div
            id="tour-help-icon"
            class="icon-container ml-3"
            v-if="!isBranded && screenWidth > 800 && changeTitleName() === 'HOME'"
          >
            <i class="icon-container__icon ion ion-bulb-sharp" @click.stop="triggerPageTour()"></i>
            <b-popover
              show
              target="tour-help-icon"
              placement="right"
              content="Take tour"
            ></b-popover>
          </div>
        </div>
        <Searchbar></Searchbar>
      </div>
      <div class="avatar-content">
        <AvatarSidebar v-if="screenWidth && screenWidth <= 992"></AvatarSidebar>
        <Avatar v-else-if="screenWidth >= 991"></Avatar>
      </div>
    </div>
    <div class="title-content" v-if="changeTitleName() && screenWidth <= 800">
      <span> {{ changeTitleName() }}</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Searchbar from "./Searchbar.vue";
import Avatar from "./Avatar.vue";
import AvatarSidebar from "./AvatarSidebar.vue";
import CollapseSidebar from "../Extend/CollapseSidebar";
import onReSize from "../Extend/onResize";

export default {
  name: "Header",
  mixins: [CollapseSidebar, onReSize],
  components: {
    Searchbar,
    Avatar,
    AvatarSidebar,
  },
  data() {
    return {
      sidebarOption: null,
      haveActiveTour: false,
      executedTour: null,
      isBranded: null
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.changeTitleName();
      },
    },
    // executeFirstTimeDashboardTour() {
    //   if (this.executeFirstTimeDashboardTour) {
    //     const { haveTour } = this.$route.meta;
    //   }
    // },
  },
  computed: {
    ...mapGetters({
      // executeFirstTimeDashboardTour: "statistics/getLoadedDashboardInformation",
    }),
  },
  created() {
    this.executedTour = this.$store.getters["user/getMyInfo"].missing_tours;
    this.isBranded = this.$store.getters["login/getTokenInfo"].is_branded;
    if (!this.isBranded) {
      this.executeTourFirstTime('payment-hub');
    }
  },
  methods: {
    toggleMenu(value) {
      this.$store.commit("changeDataSidebar", value);
    },
    changeTitleName() {
      const sidebarOption = this.$route.name.toLowerCase();
      switch (sidebarOption) {
        case "dashboard":
          return "HOME";
        case "myloads":
          return this.$t("myLoads.myLoads");
        case "loadsdetails":
          return this.$t("myLoads.myLoads");
        case "quotebook":
          return this.$t("bookALoad.bookALoad");
        case "myinsurance":
          return this.$t("myInsurance.myInsurance");
        case "quoteinsurance":
          return this.$t("myInsurance.quoteInsurance");
        case "insurancedetails":
          return this.$t("myInsurance.insuranceDetail");
        case "carriersinfo":
          return this.$t("bookALoad.bookALoad");
        case "stopsinfo":
          return this.$t("bookALoad.bookALoad");
        case "myinvoices":
          return this.$t("myInvoices.myInvoices");
        case "rolepermissions":
          return this.$t("accountManagement.accountManagement");
        case "permissions":
          return this.$t("accountManagement.accountManagement");
        case "accounts":
          return this.$t("accountManagement.accountManagement");
        case "createnewaccount":
          return this.$t("accountManagement.createNewAccount");
        case "usermanagement":
          // eslint-disable-next-line no-case-declarations
          const accountName = this.$store.getters["account/getAccountNameUserView"];
          return `${accountName.toUpperCase()} USERS`;
        // return this.$t("userManagement.userManagement");
        case "settings":
          return this.$t("settings.settings");
        case "truckmanagement":
          return this.$t("settings.truckSettings");
        case "truckalert":
          return this.$t("settings.carriersThreshold");
        case "homemosaic":
          return this.$t("settings.homeMosaic");
        case "apikeys":
          return "API KEYS";
        case "clientapikeys":
          return "API KEYS";
        case "mylocations":
          return this.$t("settings.myLocations");
        case "mymarkups":
          return this.$t("settings.myMarkups");
        case "themes":
          return "BRANDING";
        case "settingsusermanagement":
          return "USER MANAGEMENT";
        case "thememaker":
          return `BRANDING THEME ${this.$route.params.theme
            .toUpperCase()
            .charAt(this.$route.params.theme.length - 1)}`;
        case "statements":
          return "STATEMENTS";
        case "favoritecarriers":
          return "FAVORITE CARRIERS";
        case "logos":
          return "LOGOS CUSTOMIZATION";
        case "carouselmosaic":
          return "CAROUSEL MOSAIC";
        case "paymenthub":
          return "PAYMENT HUB";
        default:
          return "";
      }
    },
    executeTourFirstTime(tourName) {
      if (this.executedTour.includes(tourName)) {
        this.triggerPageTour();
        const tourExecuted = this.executedTour.indexOf(tourName);
        if (tourExecuted !== -1) {
          this.executedTour.splice(tourExecuted, 1);
        }
        this.$store.dispatch("user/updateUserTour", tourName);
      }
    },
    triggerPageTour() {
      if (!this.isBranded) {
        this.paymentHubTour();
      }
    },
    paymentHubTour() {
      const { driver } = window.driver.js;
      const paymentTour = driver({
        showProgress: true,
        showButtons: ["next", "previous"],
        steps: [
          {
            element: ".icon-container__icon",
            popover: {
              title: "Payment Hub",
              description:
                "We're thrilled to introduce our latest feature: the Payment Hub Tab! Explore how it streamlines your invoice payment process.",
              side: "left",
              align: "start",
              onNextClick: () => {
                this.$router.push({ name: "paymentHub" });
                setTimeout(() => {
                  paymentTour.moveNext();
                }, 500);
              },
            },
          },
          {
            element: "#pending-invoices___BV_tab_button__",
            popover: {
              description:
                // eslint-disable-next-line max-len
                "In this new functionality, you can view all your pending invoices. The table includes essential details such as load number, billing reference, billing number, due date, total amount, and  balance.",
              side: "left",
              align: "start",
            },
          },
          {
            element: ".payment-table-container",
            popover: {
              description:
                // eslint-disable-next-line max-len
                "By clicking the icon at the end of the table, you can quickly access detailed load information.",
              side: "left",
              align: "start",
            },
          },
          {
            element: ".card-payment-hub",
            popover: {
              description:
                // eslint-disable-next-line max-len
                "Choose the invoices you want to pay, one by one, tailored to your needs. You can also opt for the 'past due' option to automatically select all overdue invoices. If you prefer, pay all your outstanding invoices at once by selecting the total balance.",
              side: "left",
              align: "start",
              onNextClick: () => {
                this.$store.commit("paymentHub/setChangeTabSelected", true);
                setTimeout(() => {
                  paymentTour.moveNext();
                }, 500);
              },
            },
          },
          {
            element: "#payment-history___BV_tab_button__",
            popover: {
              // eslint-disable-next-line max-len
              description: "In the payment history tab, you'll find a comprehensive record of each payment you've made. Each entry includes a unique payment number and the status of the process, which can be Pending, Scheduled, In-Progress, Paid, Expired and Failed.",
              side: "left",
              align: "start",
              onPrevClick: () => {
                this.$store.commit("paymentHub/setChangeTabSelected", false);
                setTimeout(() => {
                  paymentTour.movePrevious();
                }, 500);
              }
            },
          },
          {
            element: ".historial-payment__table",
            popover: {
              // eslint-disable-next-line max-len
              description: "In addition to payment date and amount, the table offers useful tools. Here, you can retry a payment that wasn't completed, check the payment status on Melio with a single click, and download your payment receipt whenever needed.",
              side: "left",
              align: "start",
            },
          },
          {
            element: ".historial-payment__table",
            popover: {
              description:
                "Discover how the Payment Hub Tab makes managing your payments easier than ever before.",
              align: "end",
              onNextClick: () => {
                this.$store.commit("paymentHub/setChangeTabSelected", false);
                paymentTour.moveNext();
              },
            },
          },
        ],
      });
      paymentTour.drive();
    },
  },
};
</script>

<style lang="scss">
.header {
  width: 97%;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
.header-container {
  display: flex;
  align-items: center;
  padding: 0px;
  height: 54px;
}
.title-content {
  display: flex;
  align-items: center;
  margin-top: 15px;
  text-align: left;
  @include font-main-title;
  padding-left: 0.625rem;
  font-weight: bold;
  color: $color-primary-title;
  @media (max-width: 800px) {
    margin-top: 0px;
    padding-top: 1rem;
  }
}
.searchbar-container {
  display: flex;
  justify-content: space-between;
  align-items: initial;
  width: calc(100% - 100px);
  margin-right: 10px;
  @media (max-width: 800px) {
    margin-top: 10px;
  }
}
.avatar-content {
  position: relative;
  width: 207px;
  display: flex;
  flex-direction: column-reverse;
  justify-content: start;

  @media (max-width: 992px) {
    width: 100px;
  }
}
.icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid $color-primary-company;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: $color-primary-company;
  &:hover {
  }
  &__icon {
    cursor: pointer;
    font-weight: bold;
    font-size: 18px;
    color: yellow;
    &:hover {
    }
  }
}
.menu-icon-content {
  display: flex;
  cursor: pointer;
  margin-right: 10px;
  align-items: center;
  color: $color-border-container;
  font-size: 40px;
  font-weight: bold;
  @media (min-width: 993px) {
    display: none;
  }
}
</style>
