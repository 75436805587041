<template>
  <div class="avatar" :class="accordionClasses" id="myUserInformation">
    <div class="avatar-header" @click="toggleAccordion">
      <!-- <div class="avatar-header__image">
        <el-avatar shape="square" :src="squareUrl"> </el-avatar>
      </div> -->
      <div class="avatar-header__information">
        <p class="avatar-header__username">{{ username }}</p>
        <p class="avatar-header__firstname">{{ firstName }}</p>
      </div>
      <div class="avatar-header__icon">
        <i class="el-icon-arrow-down el-icon--right"></i>
      </div>
    </div>
    <div class="avatar-body" v-if="myInfo">
      <hr class="divider" />
      <div class="avatar-body__content">
        <!-- <personal-information :myInfo="myInfo" /> -->
        <!-- <CompanyInformation :myInfo="myInfo" :accountInfo="accountInfo" /> -->
        <!-- <div class="avatar-body__language">
          <p>{{ $t("avatar.language") }}:</p>
          <LanguageSelector></LanguageSelector>
        </div> -->
        <!-- <color-selector /> -->
        <!-- <hr class="divider mb-0" /> -->
        <div class="avatar-body__logout" v-if="!tokenInfo.magaya_guid">
          <div class="avatar-body__logout-content" @click="logout()">
            <b>{{ $t("avatar.logout") }}</b>
            <i class="ion ion-log-out-outline"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import LanguageSelector from "./LanguageSelector.vue";
// import CompanyInformation from "./CompanyInformation.vue";
// import PersonalInformation from "./PersonalInformation.vue";
// import ColorSelector from "./ColorSelector.vue";
import SetLogoutPath from "../utils/SetLogoutPath";

export default {
  name: "Avatar",
  components: {
    // PersonalInformation,
    // CompanyInformation,
    // LanguageSelector,
    // ColorSelector,
  },
  data() {
    return {
      isOpen: true,
      squareUrl: "https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png",
      myInfo: null,
      accountInfo: null,
      username: null,
      firstName: null,
      tokenInfo: null,
    };
  },
  async created() {
    this.tokenInfo = this.$store.getters["login/getTokenInfo"];
    this.username = this.tokenInfo.username;
    this.myInfo = this.$store.getters["user/getMyInfo"];
    // if (!this.myInfo) {
    //   this.myInfo = await this.$store.dispatch("user/getAllMyInfo");
    // }
    this.firstName = this.$store.getters["user/getMyInfo"].first_name;
    if (this.myInfo.accounts.length > 0) {
      this.accountInfo = await this.$store.dispatch(
        "account/getAccountById",
        this.myInfo.accounts[0].id
      );
    }
  },
  computed: {
    accordionClasses() {
      return { "is-closed": this.isOpen };
    },
  },
  methods: {
    toggleAccordion() {
      this.isOpen = !this.isOpen;
      const toggleAccordion = document.getElementById("myUserInformation");
      window.onclick = (event) => {
        if (!toggleAccordion.contains(event.target)) {
          this.isOpen = true;
        }
      };
    },
    logout() {
      SetLogoutPath();
      localStorage.clear();
    },
  },
};
</script>

<style lang="scss" scoped>
.avatar {
  width: 205px;
  transition: 0.2s all;
  top: 0;
  border-radius: 15px;
  z-index: 210;
  font-family: $font-family-portal;
  color: $color-primary-company;
  background: #efefef;
  border-radius: 15px;
  box-shadow: 2px 2px 5px #8a8a8a;
  @include font-small-information;
}
.avatar-body {
  padding: 0;
  overflow: hidden;
  &__language {
    text-align: left;
    margin: 5px 0px 0px 5px;
    p {
      font-weight: bold;
    }
  }
  &__logout {
    padding: 5px 10px;
    &:hover {
      background-color: $color-primary-company;
      color: $color-light;
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
    }
  }
  &__logout-content {
    cursor: pointer;
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 0px 5px;
    i {
      margin-left: 5px;
    }
  }
}
.avatar-header {
  padding: 0px 10px;
  display: flex;
  flex-direction: row;
  // align-items: center;
  cursor: pointer;
  &__information {
    width: 90%;
    margin-left: 2px;
    text-align: left;
    padding: 0.53rem;
  }
  &__icon {
    width: 17px;
    margin-top: 18px;
  }
  &__firstname {
    font-weight: bold;
  }
  &__username,
  &__firstname {
    margin: 0px;
    word-break: break-all;
    // text-overflow: ellipsis;
    // overflow: hidden;
    // width: 105px;
    // color: $color-primary-company;
    // margin-bottom: 0;
  }
}
i {
  transform: rotate(180deg);
  transition: 0.3s all;
}
.is-closed {
  background: #efefef;
  .avatar-header {
    &__username,
    &__firstname {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      color: $color-primary-company;
      margin-bottom: 0;
    }
  }
}
.is-closed .avatar-body {
  max-height: 0;
}
.is-closed i {
  transform: rotate(0deg);
  transition: 0.3s all;
}
.divider {
  margin: 0px;
  margin-top: 7px;
}
</style>
