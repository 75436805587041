<template>
  <div class="searchbar" :style="$route.name.toLowerCase() === 'home' ? 'width:100%' : ''">
    <div class="searchbar-content">
      <i class="ion ion-search-outline searchbar-content__icon" @click="search"></i>
      <div class="searchbar-content__input-container">
        <input
          v-model="searchText"
          type="text"
          :placeholder="$t('searchbar.search')"
          class="searchbar-content__input"
          id="searchInputId"
          v-on:keyup.enter="search()"
        />
        <button
          v-show="searchText"
          class="searchbar-content__delete-word"
          @click="clearSearchField"
        >
          X
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Searchbar",
  data() {
    return {
      searchText: "",
    };
  },
  computed: {
    ...mapGetters({ vuexSearchText: "load/getSearchText" }),
  },
  watch: {
    searchText() {
      if (this.searchText === "") {
        this.$store.commit("load/setSearchText", this.searchText);
      }
    },
    vuexSearchText() {
      if (!this.vuexSearchText) {
        this.searchText = "";
      }
    },
  },
  methods: {
    search() {
      if (this.$route.name !== "MyLoads") {
        this.changeView("MyLoads");
      }
      this.$store.commit("load/setSearchText", this.searchText);
    },
    clearSearchField() {
      this.searchText = "";
    },
    changeView(routeName) {
      this.$router.push({
        name: routeName,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.searchbar {
  width: 37%;
  height: 36px;
  padding: 5px 10px;
  border-radius: 15px;
  border: 2px solid #c0c4cc;
  margin-top: 15px;
  @media (max-width: 800px) {
    width: 100%;
    margin-top: 0px;
  }
  &:hover .searchbar-content__delete-word {
    visibility: visible;
  }
}
.searchbar-content {
  position: relative;
  display: flex;
  text-align: start;
  &__icon {
    color: #7e84a3;
    height: auto;
    cursor: pointer;
    color: $color-border-container;
  }
  &__input-container {
    width: 100%;
  }
  &__input {
    width: 100%;
    margin-left: 5px;
    padding-right: 27px;
    background-color: transparent;
    border: none;
    outline: none;
    text-align: left;
    font-family: $font-family-portal;
    @include font-small-standart-text;
    letter-spacing: 0px;
    color: $color-primary-company;
  }
  &__delete-word {
    position: absolute;
    right: 0;
    top: 0;
    border: none;
    background: none;
    color: #5a607f;
    visibility: hidden;
  }
}
</style>
