export default {
  data() {
    return {
      screenWidth: null,
    };
  },
  watch: {
    screenWidth() {
      this.behaviorSidebarWidth();
    },
  },
  mounted() {
    this.screenWidth = window.innerWidth;
    window.addEventListener("resize", this.onReSize);
  },
  methods: {
    onReSize() {
      this.screenWidth = window.innerWidth;
    },
    behaviorSidebarWidth() {
      if (this.screenWidth >= 993) {
        this.$store.commit("changeDataSidebar", true);
      } else {
        this.$store.commit("changeDataSidebar", false);
      }
    },
  },
};
